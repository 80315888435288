import ApiService from "@/common/api.service";

export default {
  fetchTag(tagId) {
    return ApiService.get(`tag/${tagId}`);
  },
  fetchTags(type) {
    return ApiService.query("tags", { type });
  },
  addTag(tag) {
    return ApiService.post("tag", {
      type: tag.type,
      name_tw: tag.name.tw,
      name_en: tag.name.en,
      name_cn: tag.name.cn
    });
  },
  updateTag(tagId, tag) {
    return ApiService.post(`tag/${tagId}`, {
      type: tag.type,
      name_tw: tag.name.tw,
      name_en: tag.name.en,
      name_cn: tag.name.cn
    });
  },
  deleteTag(tagId) {
    return ApiService.delete(`tag/${tagId}`);
  }
};
