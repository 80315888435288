<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="$route.query.tagType">
          <router-link
            :to="{ name: 'tags', query: { tagType: $route.query.tagType } }"
          >
            {{ $t(`pageTitle.${tagTypeDict[$route.query.tagType]}`) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="isCreateMode">
          {{ $t("pageTitle.createTag") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          {{ $t("pageTitle.editTag") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-form label-width="140px" ref="form" :model="tag">
      <el-form-item
        :label="$t('tag.type')"
        prop="type"
        :rules="commonRules.select"
      >
        <el-select
          :disabled="Boolean($route.query.tagType) && isCreateMode"
          style="width: 100%"
          :value="tag.type"
          @input="type => setTag({ type })"
        >
          <el-option
            v-for="type in Object.keys(tagTypeDict)"
            :key="type"
            :label="$t(`pageTitle.${tagTypeDict[type]}`)"
            :value="type"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('tag.name.en')"
        prop="name.en"
        :rules="commonRules.input"
      >
        <el-input :value="tag.name.en" @input="name => setName({ en: name })" />
      </el-form-item>
      <el-form-item
        :label="$t('tag.name.tw')"
        prop="name.tw"
        :rules="commonRules.input"
      >
        <el-input :value="tag.name.tw" @input="name => setName({ tw: name })" />
      </el-form-item>
      <el-form-item
        :label="$t('tag.name.cn')"
        prop="name.cn"
        :rules="commonRules.input"
      >
        <el-input :value="tag.name.cn" @input="name => setName({ cn: name })" />
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" @click="saveTag">
        {{ $t("button.save") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import formMixin from "@/mixins/form";
import tagApi from "@/apis/tag";
import Tag from "@/views/tags/Tag";
import tagTypeDict from "./tagTypeDict";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.createTag")} - Ivy-Way Academy`
    };
  },
  mixins: [formMixin],
  components: {
    Breadcrumb
  },
  data() {
    return {
      tag: new Tag()
    };
  },
  computed: {
    isCreateMode() {
      return this.$route.name === "createTag";
    },
    isEditMode() {
      return this.$route.name === "editTag";
    },
    tagId() {
      return this.$route.params.tagId;
    },
    tagTypeDict() {
      return tagTypeDict;
    }
  },
  async created() {
    if (this.isEditMode) {
      const { tag } = await tagApi.fetchTag(this.tagId);
      this.tag = new Tag({
        type: tag.type,
        name: tag.name
      });
    } else {
      this.setTag({ type: this.$route.query.tagType });
    }
  },
  methods: {
    setTag(value) {
      this.tag = new Tag({
        ...value,
        name: { ...this.tag.name }
      });
    },
    setName(name) {
      this.tag = new Tag({
        type: this.tag.type,
        name: { ...this.tag.name, ...name }
      });
    },
    async saveTag() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }
      try {
        if (this.isCreateMode) {
          await this.createTag();
        } else if (this.isEditMode) {
          await this.updateTag();
        }
        if (this.$route.query.tagType) {
          this.$router.push({
            name: "tags",
            query: { tagType: this.$route.query.tagType }
          });
        } else {
          this.$router.push({ name: "tags" });
        }
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateTag() {
      await tagApi.updateTag(this.tagId, this.tag);
      this.$message.success(this.$t("message.update_success"));
    },
    async createTag() {
      await tagApi.addTag(this.tag);
      this.$message.success(this.$t("message.create_success"));
    }
  }
};
</script>
