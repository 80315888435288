const tagTypeDict = {
  user: "userTags",
  student: "studentTags",
  teacher: "teacherTags",
  parent: "parentTags",
  question_grammar: "questionGrammarTags",
  question_math: "questionMathTags",
  question_reading: "questionReadingTags",
  question_science: "questionScienceTags"
};

export default tagTypeDict;
